import React from "react"

import ProofOfPaymentInvalid from "components/ProofOfPayment/ProofOfPaymentInvalid"
import Layout from "layout/Layout"

export default ({ location }) => {
  return (
    <Layout title="Invalid Order" seoTitle="Invalid">
      <ProofOfPaymentInvalid location={location} />
    </Layout>
)}
